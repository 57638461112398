import React from 'react';
import * as styles from "./styles.module.scss";
import ToolCard from '../../atoms/ToolCard/ToolCard';
import Slider from "react-slick";
import "../../../styles/slick-theme.scss";
import "../../../styles/slick.scss";
import ArrowRight from "../../../svg/icon-arrow-right.svg";
import ArrowLeft from "../../../svg/icon-arrow-left.svg";

export default function ToolMatch() {
    const tools = [
        { id: 1, toLink: "library", name: 'Library', description: 'A designated space where you can add and organize your content.', image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708355147/Templates/Rectangle_14800_f4agns.png" },
        { id: 2, toLink: "wizr-x", name: 'WiZR X', description: 'WiZR X is the ultimate tool for connecting and collaborating.', image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708356264/Templates/Rectangle_14801_v39wbo.png" },
        { id: 3, toLink: "mpublisher", name: 'MPublisher', description: 'Solution that will help you transform your visual game in every aspect.', image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708356460/Templates/Rectangle_15009_ywlqff.png" },
      ];
    const settings = {
        dots: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                dots: false,
                arrows: false,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: false,
                arrows: false,
              }
            }
          ],
        prevArrow: (
            <div>
              <ArrowLeft />
            </div>
          ),
          nextArrow: (
            <div>
              <ArrowRight />
            </div>
          ),
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
    }
  return (
    <div className={`${styles.container} pricingSlider toolsSlider`}>
        <h1 className={styles.title}>Unleash the Power of Your Tools</h1>
        <a href='/practical-uses' className={styles.link}>See best practices</a>
        <div className={styles.toolCards}>
        <Slider {...settings}>
            {tools.map((tool) => (
                <div key={tool.id} className={styles.toolCards}>
                    <ToolCard {...tool} />
                </div>
            ))}
        </Slider>
        </div>
        <button className={styles.button} onClick={() => window.open("https://accounts.masterwizr.com/sign-up", "_blank")} >Try for free</button>
        {/* <div className={styles.banner}>
        <h1 className={styles.subTitle}>Product Highlights</h1>
        </div> */}
    </div>
  )
}
